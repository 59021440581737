import { render, staticRenderFns } from "./contacto.vue?vue&type=template&id=6cdff6a8&scoped=true&lang=pug&"
var script = {}
import style0 from "./contacto.vue?vue&type=style&index=0&id=6cdff6a8&prod&lang=sass&scoped=true&"
import style1 from "./contacto.vue?vue&type=style&index=1&id=6cdff6a8&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6cdff6a8",
  null
  
)

export default component.exports